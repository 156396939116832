/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import FileInput from "components/Form/FileInput";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { authHeader, handleResponse, onlyAuthHeader } from "helpers";
import React, { useRef, useEffect, useState } from "react";
import { BASE_URL } from "utils/requests";
import * as Yup from "yup";
import { Category, CostCenter } from "types/dataResponse";

interface FormData {
  description: string;
  costCenter: string;
  category: string;
  value: string;
  date: Date;
  file: any;
}

const CreateExpense = (props: any) => {
  const [status, setStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [limitValue, setLimitValue] = useState<number>(0);
  const [valueInputLabel, setValueInputLabel] = useState("Valor");
  const [valueInputPlaceHolder, setValueInputPlaceHolder] = useState(
    "Digite o valor da despesa"
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validDateRange = () => {
    const today = new Date()
    const currentDay = today.getDate()
    const currentMonth = today.getMonth()
    let range = {min: new Date(), max: new Date()}
    var dayOfWeek = today.getDay();

    //6 = Sábado
    if(dayOfWeek === 6 && currentDay < 11){
      range.min = new Date(today.getFullYear(), currentMonth, 11 + 2)
      range.max = new Date(today.getFullYear(), currentMonth + 1, 10)
    }
    //0 = Domingo
    else if(dayOfWeek  === 0 && currentDay < 11){
      range.min = new Date(today.getFullYear(), currentMonth, 11 + 1)
      range.max = new Date(today.getFullYear(), currentMonth + 1, 10)
    }
    else if(currentDay < 11){
      range.min = new Date(today.getFullYear(), currentMonth - 1, 11)
      range.max = new Date(today.getFullYear(), currentMonth, 10)
    }
    else{
      range.min = new Date(today.getFullYear(), currentMonth, 11)
      range.max = new Date(today.getFullYear(), currentMonth + 1, 10)
    }

    return range;
  }

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const schema = Yup.object().shape({
        description: Yup.string()
          .min(3, "A descrição digitada é muito curta")
          .required("A descrição é obrigatória"),
        costCenter: Yup.string().required("Selecione um centro de custo"),
        category: Yup.string().required("Selecione uma categoria"),
        value: Yup.number()
          .max(limitValue, () => {
            if (limitValue)
              return (
                "A categoria escolhida possui um limite de R$" +
                limitValue.toFixed(2).replace(".", ",")
              );
            else return "Sem categoria escolhida";
          })
          .typeError("Digite um número")
          .required("Digite um valor"),
        date: Yup.date()
          .nullable(false)
          .typeError("É necessário informar uma data")
          .max(new Date(), "Solicitações com datas futuras não são aceitas"),
        file: Yup.mixed().test(
          "file",
          "Você precisa anexar um arquivo",
          (value) => {
            return !!value.length;
          }
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      const fd = new FormData();
      fd.append("file", data.file[0].file);
      fd.append(
        "solicitation",
        JSON.stringify({
          description: data.description,
          category: {
            id: Number.parseInt(data.category),
          },
          costCenter: {
            id: Number.parseInt(data.costCenter),
          },
          value: data.value,
          date: data.date,
        })
      );

      const requestOptions = {
        method: "POST",
        headers: onlyAuthHeader(),
        body: fd,
      };

      fetch(`${BASE_URL}/solicitation`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          formRef.current?.reset();
          props.history.push("/");
        })
        .catch((error) => {
          setStatus(error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadCategories = (e) => {
    const idCostCenter = e?.value;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/category/bycostcenter/` + idCostCenter, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCategories(response.data);
      });
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/costcenter`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCostCenters(response.data);
      });
  }, []);

  return (
    <div className="container">
      {status && <div className={"alert alert-danger"}>{status}</div>}
      <h3 className="display-5 font-weight-light text-secondary">
        Cadastrar Despesa
      </h3>
      <p className="lead font-weight-light text-secondary">
        Preencha os dados abaixo:
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
          <h5 className="pb-3">Dados</h5>

          <Select
            label="Centro de custo"
            name="costCenter"
            placeholder="Escolha um centro de custo"
            onChange={loadCategories}
            options={costCenters?.map((costCenter) => ({
              value: costCenter.id,
              label: costCenter.costCenter + " | " + costCenter.description,
            }))}
          />

          <Input
            label="Descrição"
            name="description"
            type="text"
            placeholder="Informação breve sobre a despesa"
          />

          <div className="form-group row">
            <Select
              label="Categoria"
              name="category"
              divClass="col"
              placeholder="Escolha uma categoria"
              onChange={(e) => {
                var catSelected = categories.find((x) => x.id === e?.value);

                setLimitValue(catSelected?.limitValue || 0);

                if (catSelected?.special) {
                  setValueInputLabel("Quantidade de KM");
                  setValueInputPlaceHolder(
                    "Digite a quilometragem rodada - Valor por KM: R$" +
                      catSelected.specialValue?.toFixed(2).replace(".", ",")
                  );
                } else {
                  setValueInputLabel("Valor");
                  setValueInputPlaceHolder("Digite o valor da despesa");
                }
              }}
              options={categories?.map((category) => ({
                value: category.id,
                label: category.codeExpense + " | " + category.description,
              }))}
            />

            <Input
              label={valueInputLabel}
              name="value"
              type="number"
              step=".01"
              placeholder={valueInputPlaceHolder}
              divClass="col"
            />
          </div>

          <Input
            label="Data da despesa"
            name="date"
            type="date"
          />

          <FileInput
            name="file"
            placeholder="Arquivo para a comprovação da despesa"
            label="Adicione o anexo"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" className="btn btn-warning mx-4" disabled={isSubmitting}>
            Salvar
          </button>
          <a href="/" className="btn btn-outline-warning mx-4 text-reset">
            Cancelar
          </a>
        </div>
      </Form>
    </div>
  );
};

export default CreateExpense;
