/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { DataPage } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Pagination from "../Pagination";
import { PencilFill, Search, TrashFill } from "react-bootstrap-icons";
import { authHeader, handleResponse } from "helpers";

const returnUserPermission = (permission: string) => {
  if (permission === "user") {
    return "Usuário";
  } else if (permission === "admin") {
    return "Administrador";
  } else if (permission === "manager") {
    return "Gestor";
  } else {
    return "";
  }
};

const DataTable = () => {
  const [status, setStatus] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [page, setPage] = useState<DataPage>({
    first: true,
    last: true,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const loadUsers = (activePage: number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${BASE_URL}/users?page=${activePage}&size=20&sort=name,asc`,
      requestOptions
    )
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      });
  };

  const searchUsers = (query: string, activePage: number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${BASE_URL}/users/search?query=${query}&page=${activePage}&size=20&sort=name,asc`,
      requestOptions
    )
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      })
      .catch((error) => {
        setStatus(error.message);
        setTimeout(() => setStatus(null), 5000);
      });
  };

  const deleteUser = async (name: string, email: string, id: number) => {
    const result = window.confirm(
      `Você realmente deseja desabilitar o usuário ${name} com o email ${email}?`
    );
    if (result) {
      const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
      };

      fetch(`${BASE_URL}/users/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => loadUsers(0))
        .catch((error) => {
          setStatus(error);
          setTimeout(() => setStatus(null), 10000);
        });
    }
  };

  useEffect(() => {
    if (isSearching) {
      searchUsers(searchTerm, activePage);
    } else {
      loadUsers(activePage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, isSearching, toggleRefresh]);

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setIsSearching(false);
      setActivePage(0); 
    } else {
      setIsSearching(true);
      setActivePage(0);
    }

    setToggleRefresh(!toggleRefresh);
  };

  return (
    <>
      <div className="mt-4 mb-5">
        {status && <div className={"alert alert-danger"}>{status}</div>}

        <h5 className="font-weight-light text-secondary">Usuários</h5>
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="input-group"
            style={{ width: "300px", position: "relative" }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Buscar usuário"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                borderRadius: "25px",
                borderColor: "#ced4da",
                paddingRight: "35px",
                paddingLeft: "15px",
                boxShadow: "none",
                outline: "none",
              }}
            />

            <button
              className="btn"
              onClick={handleSearch}
              style={{
                position: "absolute",
                right: "10px",
                top: "45%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                border: "none",
                padding: "0",
                zIndex: 10,
                outline: "none",
                cursor: "pointer",
                boxShadow: "none",
              }}
            >
              <Search
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#6c757d",
                  outline: "none",
                  cursor: "pointer",
                  boxShadow: "none",
                }}
              />
            </button>
          </div>
          {<Pagination page={page} onPageChange={changePage} />}
          <a href="/users/new" className="btn btn-warning">
            Adicionar
          </a>
        </div>
        <table className="table table-hover shadow rounded bg-white text-center p-0 m-0">
          <thead>
            <tr>
              <th scope="col">Usuário</th>
              <th scope="col">E-mail</th>
              <th scope="col">Permissão</th>
              <th scope="col">Empresa</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody className="table-borderless">
            {Array.isArray(page.content) && page.content?.length > 0 ? ( page.content?.map((item) => (
              <tr key={item.id}>
                <th scope="row">{item.name}</th>
                <td>{item.email}</td>
                <td>{returnUserPermission(item.role)}</td>
                <td>{item.company.name}</td>
                <td>
                  <a
                    className="text-secondary"
                    href={`/users/edit/${item.id}`}
                  >
                    <PencilFill />
                  </a>{" "}
                  <a
                    className="text-secondary"
                    href="#"
                    onClick={() => deleteUser(item.name, item.email, item.id)}
                  >
                    <TrashFill />
                  </a>
                </td>
              </tr>
              ))
            ) : (
              <tr>
                <td >Nenhum usuário encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
